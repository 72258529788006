import consumer from "./consumer"
import { patch } from '@rails/request.js'

function scrollLastMessageIntoView() {
  const messages = document.querySelectorAll('.chat-room-message');
  const lastMessage = messages[messages.length - 1];

  if (lastMessage !== undefined) {
    lastMessage.scrollIntoView();
  }
}

function refreshChat(messageHTML) {
  const messagesContainer = document.querySelector('.chat-room-messages');
  messagesContainer.insertAdjacentHTML('beforeend', messageHTML);
  document.getElementById("new_message").reset();
  const input = document.querySelector('#message_content');
  input.blur();
  scrollLastMessageIntoView();
}

function seenNewMessages(channelId) {
  patch(`/chat_rooms/${channelId}/messages/read_all.json`)
}

function notif(chat_room_id) {
  const unread_messages_count_div = document.querySelector('.notification');
  const mobileNotif = document.querySelector('.mobile_notif');
  const messageNotifVisio = document.querySelector('.message_notif_visio');
  const user_list = document.querySelector('.user[data-chat-room="' +String(chat_room_id)+'"] > .fa-circle');

  if (mobileNotif) {
    mobileNotif.classList.add('notif');
  }

  if (messageNotifVisio) {
    messageNotifVisio.classList.remove('d-none');
  }

  if (unread_messages_count_div){
    unread_messages_count_div.innerHTML = (parseInt(unread_messages_count_div.innerHTML)+1)
    document.querySelector('title').innerHTML = "("+parseInt(unread_messages_count_div.innerHTML) +") Simundia"
  }

  if (user_list){
    user_list.classList.add('notification');
  }

  const audio = document.querySelector('#notification-sound');
  if (audio){
    audio.play();
  }
}


// display chatRoom in visio
function subscribeToChatRooms() {
  if (document.getElementById('user-id')) {
    const currentUserId = document.getElementById('user-id').dataset.user_id;
    const chatRooms = document.querySelectorAll(".chat-room-subscription");
    if (chatRooms.length > 0){
      Array.prototype.forEach.call(chatRooms, function(element){
        const chatRoomId = element.dataset.chat_room_id;
        consumer.subscriptions.create(
          {channel: "ChatRoomsChannel", chat_room_id: chatRoomId}, {
          received(data) {
            if (data.current_user_id !== parseInt(currentUserId)) {
              var chatRoom = document.getElementById('chat-room');
              var messagesContainer = document.querySelector(".chat-room-messages[data-channel="+CSS.escape(chatRoomId)+"]");
              if (messagesContainer && chatRoom.classList.contains("hidden-chat-room")) {
                messagesContainer.insertAdjacentHTML('beforeend', data.message_partial);
                notif(chatRoomId);
              } else if (messagesContainer) {
                messagesContainer.insertAdjacentHTML('beforeend', data.message_partial);
                scrollLastMessageIntoView();
                seenNewMessages(chatRoomId);
              } else {
                notif(chatRoomId);
              }
            }
          },
        })
      });
    }
  }
}

export { scrollLastMessageIntoView, seenNewMessages, notif, subscribeToChatRooms };




