import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["table-sortable-th"];

  initialize() {}

  sortTableByColumn(table, column, asc = true) {
    const dirModifier = asc ? 1 : -1;
    const tBody = table.tBodies[0];
    const rows = Array.from(tBody.querySelectorAll("tr"));

    // Sort each row
    const sortedRows = rows.sort((a, b) => {
      const aColText = a
        .querySelector(`td:nth-child(${column + 1})`)
        .textContent.trim();
      const bColText = b
        .querySelector(`td:nth-child(${column + 1})`)
        .textContent.trim();

      const aColDataSortValue = a
        .querySelector(`td:nth-child(${column + 1})`)
        .getAttribute("data-sort-value");
      const bColDataSortValue = b
        .querySelector(`td:nth-child(${column + 1})`)
        .getAttribute("data-sort-value");

      const aSort =
        typeof aColDataSortValue == "number"
          ? parseInt(aColDataSortValue)
          : aColText;
      const bSort =
        typeof bColDataSortValue == "number"
          ? parseInt(bColDataSortValue)
          : bColText;

      return aSort > bSort ? 1 * dirModifier : -1 * dirModifier;
    });

    // Remove all existing TRs from the table
    while (tBody.firstChild) {
      tBody.removeChild(tBody.firstChild);
    }

    // Re-add the newly sorted rows
    tBody.append(...sortedRows);
  }

  sort(e) {
    const target = e.currentTarget;

    const tableElement = target.closest("table");
    const headerCellIndex = Array.prototype.indexOf.call(
      target.parentElement.children,
      target,
    );
    const currentIsAscending = target.classList.contains("th-sort-asc");

    tableElement
      .querySelectorAll("th")
      .forEach((th) => th.classList.remove("th-sort-asc", "th-sort-desc"));

    if (currentIsAscending === false) {
      target.classList.add("th-sort-asc");
      target.classList.remove("th-sort-desc");
    } else {
      target.classList.remove("th-sort-asc");
      target.classList.add("th-sort-desc");
    }

    this.sortTableByColumn(tableElement, headerCellIndex, !currentIsAscending);
  }
}
