import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "listElement"];

  filter(event) {
    const query = this.inputTarget.value.toLowerCase()

    this.listElementTargets.forEach((item) => {
      const text = item.textContent.toLowerCase()
      const words = text.split(" ")
      const isVisible = words.some(word => word.startsWith(query))
      item.style.display = isVisible ? "" : "none"
    })
  }
}
