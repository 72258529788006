import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["selectedLang"];

  initialize() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get("language")) {
      this.selectedLangTarget.value = urlParams.get("language");
    }
  }

  selectLang(event) {
    window.location.href =
      window.location.pathname + "?language=" + event.target.value;
  }
}
