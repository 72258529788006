import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "panel"];

  static values = {
    index: Number,
    contentVisible: String,
  };

  connect() {
    this.index = this.indexValue;
    this.contentVisibleValue == "hidden" ? this.hideTab() : this.showTab();
  }

  change(event) {
    this.index = this.tabTargets.indexOf(event.currentTarget);
  }

  showTab() {
    if (this.tabTargets[this.index]) {
      this.tabTargets.forEach((tab, index) => {
        const panel = this.panelTargets[index];
        tab?.classList.toggle("-is-active", index == this.index);
        panel?.classList.toggle("-is-hidden", index != this.index);
      });
    }
  }

  hideTab() {
    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index];
      panel.classList.add("-is-hidden");
    });
  }

  get index() {
    return parseInt(this.data.get("index") || 0);
  }

  set index(value) {
    this.data.set("index", value);
    this.showTab();
  }
}
