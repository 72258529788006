import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["search", "input", "selected", "inputs"];

  static values = {
    selectedOption: Array,
  };

  initialize() {
    let defaultValue;

    this.inputName = this.inputTarget.name;

    if (this.selectedTarget.hasAttribute("data-default-value")) {
      defaultValue = JSON.parse(
        this.selectedTarget.dataset.defaultValue || "[]",
      );
    }

    if (Array.isArray(defaultValue) && defaultValue.length) {
      this.defaultSelect(defaultValue);
    }
  }

  defaultSelect(value) {
    const currentSelectedOption = this.selectedOptionValue;
    currentSelectedOption.push(...value);
    this.selectedOptionValue = currentSelectedOption;
    this.refreshSelectedList();
  }

  select(e) {
    const label =
      e.target.dataset.label ||
      e.target.dataset.autocompleteLabel ||
      e.target.innerHTML;
    const value = e.target.dataset.autocompleteValue || e.target.dataset.value;
    const index = this.selectedOptionValue
      .map((obj) => obj.value)
      .indexOf(value);
    const currentSelectedOption = this.selectedOptionValue;

    if (index === -1) {
      currentSelectedOption.push({ label, value });
      this.selectedOptionValue = currentSelectedOption;
    }

    this.searchTarget.value = "";
    this.refreshSelectedList();
  }

  delete(e) {
    const value = e.target.dataset.value;
    const index = this.selectedOptionValue
      .map((obj) => obj.value)
      .indexOf(value);
    const currentSelectedOption = this.selectedOptionValue;

    if (index !== -1) {
      currentSelectedOption.splice(index, 1);
      this.selectedOptionValue = currentSelectedOption;
    }

    this.refreshSelectedList();
  }

  refreshSelectedList() {
    this.selectedTarget.innerHTML = "";
    this.inputsTarget.innerHTML = "";
    this.selectedOptionValue.forEach((item) => {
      const element = document.createElement("span");
      element.classList.add("custom-multiselect__selected-item");
      element.setAttribute("data-action", "click->custom-multiselect#delete");
      element.setAttribute("data-value", item.value);
      element.innerHTML = item.label;

      this.selectedTarget.appendChild(element);
    });

    this.setHiddenInputs();
  }

  setHiddenInputs() {
    this.selectedOptionValue.forEach((item) => {
      const inputHidden = document.createElement("input");
      inputHidden.classList.add("form-control");
      inputHidden.classList.add("hidden");
      inputHidden.setAttribute("type", "hidden");
      inputHidden.setAttribute("value", item.value);
      inputHidden.setAttribute("name", this.inputName);

      this.inputsTarget.appendChild(inputHidden);
    });
  }
}
