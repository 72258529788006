import { patch } from "@rails/request.js";
import { ApplicationController, useDebounce } from "stimulus-use";

export default class extends ApplicationController {
  static values = { url: String };

  static debounces = ["updateNote"];

  connect() {
    useDebounce(this, { wait: 2000 });
    this.lastSavedValue = this.element.value;
  }

  change() {
    this.updateNote({
      valueToSave: this.element.value,
    });
  }

  updateNote({ valueToSave }) {
    if (valueToSave === this.lastValue) return;

    const formData = new FormData();
    formData.append("relationship[note]", valueToSave);
    patch(this.urlValue, {
      body: formData,
      responseKind: "json",
    }).then(() => {
      this.lastSavedValue = valueToSave;
    });
  }
}
