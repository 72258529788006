import { Controller } from "stimulus";
import AcsVisioRecordingHelper from "@/services/visio_call/acs_visio_recording_utils";

export default class extends Controller {
  static outlets = ["visio-2"];

  async connect() {
    if (this.hasVisio2Outlet && this.visio2Outlet.visioCallAdapter) {
      await this.updateAiNotetakerBadgeStateTarget();
    }
  }

  async updateAiNotetakerBadgeStateTarget() {
    const { callRecordingHelper } = this.visio2Outlet.visioCallAdapter;
    await callRecordingHelper.updateAiNotetakerBadgeStateTarget({
      notStarted: this.visio2Outlet.aiNotetakerBadgeStateNotStartedTarget,
      inProgress: this.visio2Outlet.aiNotetakerBadgeStateInProgressTarget,
      paused: this.visio2Outlet.aiNotetakerBadgeStatePausedTarget,
      waiting: this.visio2Outlet.aiNotetakerBadgeStateWaitingTarget,
      refused: this.visio2Outlet.aiNotetakerBadgeStateRefusedTarget,
    });
  }
  async askRemoteParticipantForAiNotetakerConsent() {
    const { callRecordingHelper } = this.visio2Outlet.visioCallAdapter;
    await callRecordingHelper.askRemoteParticipantForAiNotetakerConsent();
  }

  async declineAiNotetakerConsent() {
    const { callRecordingHelper } = this.visio2Outlet.visioCallAdapter;
    await callRecordingHelper.declineAiNotetakerConsent(false);
  }

  async declineAiNotetakerConsentAndNotifyOtherUser() {
    const { callRecordingHelper } = this.visio2Outlet.visioCallAdapter;
    await callRecordingHelper.declineAiNotetakerConsent(true);
  }

  async acceptRequestedAiNotetakerConsent() {
    // coach already has ai notetaker consent given
    const { call, callRecordingHelper } = this.visio2Outlet.visioCallAdapter;
    const response = await callRecordingHelper.acceptAiNotetakerConsent();

    if (response.ok && this.isRemoteParticipantsPresent) {
      console.log("acceptRequestedAiNotetakerConsent");
      await callRecordingHelper.startRecording(call);
    }
  }

  async acceptAiNotetakerConsent() {
    const { callRecordingHelper } = this.visio2Outlet.visioCallAdapter;
    const response = await callRecordingHelper.acceptAiNotetakerConsent();

    if (response.ok && this.isRemoteParticipantsPresent) {
      await callRecordingHelper.showAiNotetakerBadgeState("notStarted");
    }
  }

  async pauseRecording() {
    const { callRecordingHelper } = this.visio2Outlet.visioCallAdapter;
    await callRecordingHelper.pauseRecording();
  }

  async resumeRecording() {
    const { callRecordingHelper } = this.visio2Outlet.visioCallAdapter;
    await callRecordingHelper.resumeRecording();
  }

  get isRemoteParticipantsPresent() {
    const { call } = this.visio2Outlet.visioCallAdapter;
    return call.remoteParticipants.length >= 1;
  }
}
